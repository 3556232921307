<template>
  <div class="w-full">
    <div style="margin-bottom: 16px">
      <UsersTableHeader :loading="loading" />
    </div>
    <div
      class="w-full overflow-x-auto rounded-xl border border-gray-300 bg-white"
    >
      <a-table
        :columns="columns"
        :data-source="users"
        :pagination="pagination"
        @change="onTableChange"
      >
        <span
          slot="actions_name"
          slot-scope="text, record"
          class="text-sm font-medium text-right whitespace-nowrap flex"
        >
          {{ record.first_name }} {{ record.last_name }}
        </span>

        <span
          slot="actions"
          slot-scope="text, record"
          class="mr-4 flex items-center text-sm font-medium text-right whitespace-nowrap flex"
        >
          <a-switch
            :checked="record.is_active"
            @change="onSwitchChange($event, record)"
            :disabled="loadingStatus"
          />
          <router-link
            :to="`./${record.key}/edit/`"
            class="mx-3 h-6 w-6 p-1 text-blue-600 dark:text-blue-500 shadow rounded cursor-pointer"
            append
          >
            <img class="h-full w-full" src="@/assets/icons/pen.svg" alt="" />
          </router-link>
          <span
            class="p-1 w-6 bg-red-600 rounded cursor-pointer"
            @click="toggleDeleteModal(record)"
          >
            <img src="@/assets/icons/trash.svg" />
          </span>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import UsersTableHeader from '@/components/Dashboard/Users/UsersTableHeader'
const columns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    sorter: () => {},
  },
  {
    title: 'Correo Electrónico',
    dataIndex: 'email',
    key: 'email',
    sorter: () => {},
  },
  {
    title: 'Entidad',
    dataIndex: 'company_name',
    key: 'company_name',
    sorter: () => {},
  },
  {
    title: 'Rol',
    dataIndex: 'role',
    key: 'role',
    sorter: () => {},
  },
  {
    title: 'Acciones',
    dataIndex: 'actions',
    key: 'actions',
    scopedSlots: { customRender: 'actions' },
  },
]

export default {
  components: {
    UsersTableHeader,
  },
  data() {
    return {
      actualUser: null,
      users: [],
      columns,
      loading: false,
      showDeleteModal: false,
      page: 1,
      pageSize: 10,
      totalItems: 10,
      pagination: {
        total: 1,
        current: 1,
        pageSize: 20,
        hideOnSinglePage: true,
      },
      query: {
        page: 1,
        page_limit: 20,
        order_by: undefined,
        direction: undefined,
      },
      loadingStatus: false,
    }
  },
  created() {
    this.setQuery()
    this.fetchUsers()
  },
  watch: {
    '$route.query': function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.fetchUsers()
      }
    },
  },
  methods: {
    async fetchUsers() {
      this.loading = true
      const response = await this.$api.users.getUsers(this.query)
      if (response.status !== 200) {
        this.$showNotification('error', response.data)
        return
      }
      this.totalItems = response.data.num_total_elements
      this.users = response.data.result
      this.loading = false
    },
    async onSwitchChange(checked, record) {
      const user = {
        ...record,
        is_active: checked,
      }
      this.loadingStatus = true
      const response = await this.$api.users.updateUser(user, user.key)
      this.loadingStatus = false

      if (response.status !== 200) {
        this.$showNotification('error', response.data)
        return
      }
      this.$showNotification('success', 'Cambio de estado éxitoso.')
      this.fetchUsers()
    },
    onTableChange(...params) {
      const pagination = params[0]
      const filters = params[1]
      const sort = params[2]
      // Sort
      if (sort.order) {
        this.query.order_by = sort.field
        this.query.direction = sort.order === 'ascend' ? 'asc' : 'desc'
      } else {
        this.query.order_by = undefined
        this.query.direction = undefined
      }
      // Filters
      if (filters) {
        for (let filter in filters) {
          this.query[filter] = filters[filter]
        }
      }
      // Pagination
      this.pagination.current = pagination.current
      this.query.page = pagination.current
      this.query.page_limit = pagination.pageSize
      this.setQuery(true)
    },
    async setQuery(updateQuery) {
      if (!this.$route.query.page || updateQuery) {
        await this.$router.push({
          query: { ...this.query },
        })
      } else {
        this.pagination.page = this.$route.query.page
        this.pagination.page_limit = this.$route.query.page_limit || 10
        await this.setQuery(true)
      }
    },
    toggleDeleteModal(user) {
      this.actualUser = user
      this.showDeleteModal = !this.showDeleteModal
    },
  },
}
</script>
