<template>
  <header class="mb-4 flex gap-3">
    <div class="w-full sm:max-w-sm grid grid-cols-1 sm:grid-cols-2 gap-4">
      <Button
        class=""
        @click.native="$router.push({ path: 'user', append: true })"
      >
        <img src="@/assets/icons/plus.svg" /> Nuevo usuario
      </Button>

      <div v-if="loading" class="flex justify-center items-center">
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 24px"
            spin
          />
        </a-spin>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    showButton: Boolean,
    loading: Boolean,
    search: String,
  },
}
</script>

<style></style>
